// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useToast
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { ThemeEditor } from "./ThemeEditor";
import { FaArrowRight, FaClipboard, FaEthereum, FaPlus, FaSearch } from "react-icons/fa";
import HOZText from "components/text/HOZText";
import apiHelper from "helpers/apiHelper";

export default function HeaderLinks(props) {
  const { setToken, token, secondary } = props;

  const toast = useToast()
  const [user, setUser] = React.useState(null)

  useEffect(() => {
      apiHelper.getUser().then((res) => {
        console.log(res)
        setUser(res)
      })
  }, [])

  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.200", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const history = useHistory();
  const routeChange = (token) => {
    if (token == "")
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
    let path = `/`;
    history.push(path);
  }
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}>

      <SidebarResponsive routes={routes} />

      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color='white'
            name={user?.email}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              {user?.email}
            </Text>
          </Flex>
          <Flex w={"100%"} flexDirection='column' p='10px'>
            <a role={"button"}
              onClick={(e) => {
                routeChange("")
              }}>
              <MenuItem
                w={"100%"}
                _hover={{ bg: "gray.200" }}
                color='red.400'
                borderRadius='8px'
                px='14px'>

                <Text w={"100%"} fontSize='sm'>Log out</Text>
              </MenuItem>
            </a>

          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
