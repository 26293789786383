import React from "react";

// Chakra imports
import { Flex, FormLabel, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue } from "@chakra-ui/react";
import { RiEyeCloseFill } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import HOZText from "components/text/HOZText";


export default function AuthStep1(props) {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");

    const {
        auth,
        setAuth,
        error,
        ...rest } = props;

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show);

    return (
        <Flex direction={"column"}>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='john.smith@email.com'
                mb='24px'
                autoComplete={"email"}
                fontWeight='500'
                size='lg'
                value={auth.user}
                onChange={(e) => {
                    setAuth((ps) => {
                        let update = { ...ps }
                        update.user = e.target.value
                        return { ...update }
                    })
                }}
            />

            <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    mb='24px'
                    size='lg'
                    autoComplete={"new-password"}
                    type={show ? "text" : "password"}
                    variant='auth'
                    value={auth.pass}
                    onChange={(e) => {
                        setAuth((ps) => {
                            let update = { ...ps }
                            update.pass = e.target.value
                            return { ...update }
                        })
                    }}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={!show ? RiEyeCloseFill : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                    />
                </InputRightElement>
            </InputGroup>
            {
                error != "false" ?
                    <HOZText mb={5} color={"brand.500"}>{error}</HOZText>
                    :
                    <HOZText color="white"></HOZText>
            }
        </Flex>
    );
}

