// Chakra imports
import { border, Button, Flex, Icon, Image, Input, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import banner from "assets/img/auth/banner.png";
import { FaPlus, FaTrash } from "react-icons/fa";
import apiHelper from "helpers/apiHelper";
import { round2 } from "helpers/util";

export default function HOZRefCard(props) {
    const { get, set, index } = props
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const bg = useColorModeValue("white", "navy.700");
    const bgItem = useColorModeValue(
        { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
        { bg: "navy.700", boxShadow: "unset" }
    );
    return (
        <Card
            _hover={bgItem}
            bg='transparent'
            boxShadow='unset'
            px='24px'
            py='21px'
            transition='0.2s linear'>
            <Flex direction={{ base: "column" }} justify='center'>
                <Flex position='relative' align='center'>
                    <Image src={banner} w='66px' h='66px' borderRadius='20px' me='16px' />
                    <Flex
                        direction='column'
                        w={{ base: "70%", md: "100%" }}
                        me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>
                        {set ?
                            <Input
                                onChange={
                                    (e) => {
                                        set((ps) => {
                                            let update = { ...ps }
                                            update.positions[index].name = e.target.value
                                            return update
                                        })
                                    }}
                                color={"black"}
                                fontSize={{
                                    base: "md",
                                }}
                                value={get?.positions[index].name}
                                p={0}
                                fontWeight='bold'
                                me='14px'
                                w={"60%"}
                                style={{
                                    border: "none"
                                }}>
                            </Input>
                            :
                            <Text w={"100%"} p={0}
                                fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }}
                                textAlign={"left"}
                                style={{
                                    border: "none"
                                }}>{get?.positions[index].name}</Text>}

                        <Flex
                            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                            align='center' direction="row"
                            w={"100%"}>
                            <Text fontWeight='700' fontSize='md' color={textColorPrimary}>
                                Pay
                            </Text>

                            <Flex
                                me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                                align='center' direction="row"
                                fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }} >
                                <Text position={"relative"} ml={2} textAlign={"right"} placeContent={"end"} flexGrow={true} >
                                    $
                                </Text>
                                {
                                    set ?
                                        <Input w={"100%"} p={0}
                                        onChange={
                                            (e) => {
                                                set((ps) => {
                                                    let update = { ...ps }
                                                    update.positions[index].pay_rate = e.target.value != "" ? e.target.value : 0
                                                    return update
                                                })
                                            }}
                                            onBlur={
                                                (e) => {
                                                    set((ps) => {
                                                        let update = { ...ps }
                                                        update.positions[index].pay_rate = e.target.value != "" ? round2(e.target.value) : 0
                                                        return update
                                                    })
                                                }}
                                            textAlign={"left"}
                                            value={get?.positions[index].pay_rate}
                                            style={{
                                                border: "none"
                                            }}></Input>
                                        :
                                        <Text w={"100%"} p={0}
                                            textAlign={"left"}
                                            style={{
                                                border: "none"
                                            }}>{get?.positions[index].pay_rate}</Text>
                                }

                            </Flex>
                        </Flex>
                        {/* <Flex
                            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                            align='center' direction="row" >
                            <Text fontWeight='700' fontSize='md' color={textColorPrimary}>
                                Referee Pay
                            </Text>

                            <Flex
                                me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                                align='center' direction="row"
                                fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }} >
                                <Text position={"relative"} ml={2} textAlign={"right"} placeContent={"end"} flexGrow={true}>
                                    $
                                </Text>
                                <Text w={20} p={0}
                                    textAlign={"left"}
                                    style={{
                                        border: "none"
                                    }}>
                                    {get.refs[index].pay ? (Number.parseFloat(get.refs[index].pay) - (get.refs[index].pay * 0.20)).toFixed(2) : 0.00}
                                </Text>
                            </Flex>
                        </Flex> */}
                        {
                            set && !get.positions[index].id ?
                                <Button
                                    position='absolute'
                                    p='0px !important'
                                    right='14px'
                                    borderRadius='50%'
                                    minW='36px'
                                    h='36px'
                                    onClick={async () => {
                                        try {
                                            await apiHelper.deletePosition(get.positions[index].id)
                                            console.log({delete: get.positions[index].id})
                                        } catch (e) {
                                            console.log("No position to delete in database")
                                        }
                                        set((ps) => {
                                            let update = { ...ps }
                                            update.positions.splice(index, 1)
                                            return update
                                        })
                                    }}>
                                    <Icon as={FaTrash} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
                                </Button>
                                :
                                ""
                        }

                    </Flex>
                    {/* <Flex
                        me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                        align='center' direction="column">
                        <Text fontWeight='700' fontSize='md' color={textColorPrimary}>
                            Pay
                        </Text>
                        <Text fontWeight='700' fontSize='md' color={textColorPrimary}>$30</Text>

                    </Flex> */}

                </Flex>
            </Flex >
        </Card >
    );
}
