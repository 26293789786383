import React, { useEffect } from "react";

// Chakra imports
import { Box, Checkbox, Flex, FormLabel, Input, InputGroup, Text, useColorModeValue } from "@chakra-ui/react";
import HOZText from "components/text/HOZText";
import raw from '../resources/tos.txt';




export default function AuthStep2(props) {

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [tosText, setTosText] = React.useState("")

    useEffect(() => {
        fetch(raw)
            .then((response) => response.text())
            .then((text) => {
                setTosText(text)
            });
    }, []);

    const {
        setTOS,
        TOS,
        auth,
        setAuth,
        error,
        ...rest } = props;

    return (
        <Flex direction={"column"}>
            <Box
                mb={10}
            >
                <Text
                    fontSize='2xl'
                    fontWeight='800'
                >
                    Terms of Service & Privacy Policy
                </Text>
            </Box>
            <Box
                overflowY={"scroll"}
                height={"200px"}
                border={"1px solid"}
                borderColor={"gray.200"}
                borderRadius={"md"}
                p={4}
                bg={"white"}
            >
                {tosText}
            </Box>
            <Flex
                direction={"row"}
                alignItems={"center"}
                my={5}


            >

                <Checkbox
                    checked={TOS}
                    colorScheme='brandScheme'
                    me='10px'
                    onChange={(e) => {
                        setTOS(e.target.checked)
                    }}
                >
                    <Text
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        I agree to the Terms of Service and Privacy Policy
                    </Text>
                </Checkbox>
            </Flex>


            {
                error != "false" ?
                    <HOZText mb={5} color={"brand.500"}>{error}</HOZText>
                    :
                    <HOZText color="white"></HOZText>
            }
        </Flex>
    );
}

