import React, { useRef } from "react";
import {
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
// import "./withFormikHOC.scss";

const withFormikHOC = (Component: any, schema: any) => {
  const MyComp = (props: any) => {
    const {
      routeInitialValues,
      initialErrors,
      initialTouched,
      onSubmit,
      appBag,
      ref1,
      formClassName
    } = props;
    const initialValues = routeInitialValues ? routeInitialValues : {};
    return (
      <div>
      <Formik
          initialValues={initialValues}
          initialErrors={initialErrors ? initialErrors : {}}
          initialTouched={initialTouched ? initialTouched : {}}
          onSubmit={onSubmit}
          validateOnChange={true}
          validate={(values) => {
            // https://github.com/formium/formik/issues/506
            if (schema) {
              try {
                validateYupSchema(values, schema, true, values);
              } catch (err) {
                const errors = yupToFormErrors(err); //for rendering validation errors
                return errors;
              }
            }
            return {};
          }}
          // validationSchema={schema}
          validateOnMount={false}
          enableReinitialize={true}
        >
        {(formikProps: any) => (
          <>
            <Form className={formClassName} ref={ref1}>
              <Component formikProps={formikProps} {...props} formikRef={ref1} />
            </Form>
          </>
        )}
        </Formik>

      </div>
      );
  }
  MyComp.displayName = 'test'
  return MyComp;
}
export default withFormikHOC;