import React, { useState } from "react";
import JSONPretty from "react-json-pretty";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";

function DebugFormik(props: any) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { localData } = props;

  return (
    <>
      {process.env.REACT_APP_ENV !== "prod" && (
        <div className="mt-2 mb-2 debug-formik">
          <Button onClick={handleShow}>Debug</Button>

          <Modal isOpen={show} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Debug State</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                ##################### Environment -{" "}
                {process.env.REACT_APP_ENV} {}####################### <br />
                ####################### Local Bag ##############################
                <JSONPretty data={localData}></JSONPretty>
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      )}
    </>
  );
}

export default DebugFormik;
