import React from "react";

// Chakra imports
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import HOZText from "components/text/HOZText";
import { RiEyeCloseLine } from "react-icons/ri";
import { NavLink, useHistory } from "react-router-dom";
import apiHelper from "helpers/apiHelper";


export default function SignInPage(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const handleClick = () => setShow(!show);
  const history = useHistory()

  const routeChange = () => {
    let path = `/league/default`;
    history.push({
      pathname: path,
    });
    window.location.reload()
  }
  const {
    create,
    setCreate,
    ...rest } = props;

  const [show, setShow] = React.useState(false)
  const [user, setUser] = React.useState("")
  const [pass, setPass] = React.useState("")
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)





  return (
    <Box>
      <FormLabel
        display='flex'
        ms='4px'
        fontSize='sm'
        fontWeight='500'
        color={textColor}
        mb='8px'>
        Email<Text color={brandStars}>*</Text>
      </FormLabel>
      <Input
        bg={useColorModeValue("white", "gray.700")}
        isRequired={true}
        variant='auth'
        fontSize='sm'
        ms={{ base: "0px", md: "0px" }}
        type='email'
        placeholder='win@herdofzebras.com'
        mb='24px'
        fontWeight='500'
        size='lg'
        value={user}
        onChange={(e) => {
          setUser(e.target.value)
        }
        }
      />
      <FormLabel
        ms='4px'
        fontSize='sm'
        fontWeight='500'
        color={textColor}
        display='flex'>
        Password<Text color={brandStars}>*</Text>
      </FormLabel>
      <InputGroup size='md'>
        <Input
          bg={useColorModeValue("white", "gray.700")}

          isRequired={true}
          fontSize='sm'
          placeholder='Min. 8 characters'
          mb='24px'
          size='lg'
          type={show ? "text" : "password"}
          variant='auth'
          value={pass}
          onChange={(e) => {
            setPass(e.target.value)
          }
          }
        />
        <InputRightElement display='flex' alignItems='center' mt='4px'>
          <Icon
            color={textColorSecondary}
            _hover={{ cursor: "pointer" }}
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handleClick}
          />
        </InputRightElement>
      </InputGroup>
      <Flex justifyContent='space-between' align='center' mb='24px'>
        <FormControl display='flex' alignItems='center'>
          <Checkbox
            id='remember-login'
            colorScheme='brandScheme'
            me='10px'
          />
          <FormLabel
            htmlFor='remember-login'
            mb='0'
            fontWeight='normal'
            color={textColor}
            fontSize='sm'>
            Keep me logged in
          </FormLabel>
        </FormControl>
        <NavLink to='/auth/forgot-password'>
          <Text
            color={textColorBrand}
            fontSize='sm'
            w='124px'
            fontWeight='500'>
            Forgot password?
          </Text>
        </NavLink>
      </Flex>
      {
        error != "false" ?
          <HOZText mb={5} color={"brand.500"}>{error}</HOZText>
          :
          <HOZText></HOZText>
      }
      <Button
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        _hover={{ bg: "brand.600" }}
        w='100%'
        h='50'
        mb='24px'
        disabled={loading}
        onClick={async (e) => {
          setLoading(true)
          try {
            let resp = await apiHelper.login(user, pass)
            if (resp) {
              let leagues = await apiHelper.getUserLeagues()
              if (leagues.length > 0) {
                routeChange()
              } else {
                setCreate(true)
              }
            }
          } catch (e) {
            setError("Incorrect Username/Password")
          }
          setLoading(false)


        }}>
        {
          loading ?
            <Spinner color='white' />
            :
            <HOZText color="white">Sign In</HOZText>
        }
      </Button>
    </Box>
  )
}