/* eslint-disable */
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Progress,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import apiHelper from "helpers/apiHelper";
import React, { useMemo } from "react";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from 'moment'
// EXPORT ISNT WORKING SO......
const sportTypes = [
  { id: 1, sport: 'Soccer', icon: "football-outline" },
  { id: 2, sport: 'Lacrosse', icon: "true" },
  { id: 3, sport: 'Football', icon: "american-football-outline" },
  { id: 4, sport: 'Baseball', icon: "baseball-outline" },
  { id: 5, sport: 'Basketball', icon: "basketball-outline" },
  { id: 6, sport: 'Volleyball', icon: "true" },
  { id: 7, sport: 'Hockey', icon: "true" },
  { id: 8, sport: 'Softball', icon: "true" },
  { id: 9, sport: 'Rugby', icon: "true" },
  { id: 10, sport: 'Tennis', icon: "tennisball-outline" },
]
export default function GameTable(props) {
  const { columnsData, tableData, loading } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const columns = useMemo(() => columnsData, [columnsData]);
  const [data, setData] = React.useState(tableData);
  const skipPageResetRef = React.useRef()
  const deleteGame = async (id) => {
    try{
    await apiHelper.deleteGame(id);
      toast({
        title: "Game Deleted",
        description: "The game has been deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    skipPageResetRef.current = true;
    setData(data.filter((game) => game.id !== id))
    }catch(err){
      toast({
        title: "Error",
        description: "Game cannot be deleted, as there are already applications, please contact support",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(err)
    }
  };
  React.useEffect(() => {
    skipPageResetRef.current = false
  })
  
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      autoResetPage: !skipPageResetRef.current,
      autoResetHiddenColumns: !skipPageResetRef.current,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const history = useHistory()
  const routeChange = () => {
    let path = `/league/creategame`;
    history.push(path);
  }
  const routeChangeView = (id) => {
    let path = `/league/game/view/${id}`;
    history.push({
      pathname: path,
      state: {
        id
      }
    });
  }
  const routeChangeEdit = (id) => {
    let path = `/league/editgame/${id}`;
    history.push({
      pathname: path,
      state: {
        id
      }
    });
  }
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Games
        </Text>
        <Button
          position='absolute'
          p='0px !important'
          right='14px'
          borderRadius='50%'
          minW='36px'
          h='36px'
          onClick={() => {
            routeChange()
          }}>
          <Icon as={FaPlus} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
        </Button>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </SkeletonText>
                    );
                  } else if (cell.column.Header === "SPORT") {
                    data = (
                      <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {
                            cell.value ?
                              sportTypes.find((sport) => sport.id === cell.value).sport
                              : ""
                          }
                        </Text>
                      </SkeletonText>
                    );
                  } else if (cell.column.Header === "GAME DATE") {
                    data = (
                      <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {moment(cell.value).format('L')}
                          {/* {new Date(cell.value).toLocaleDateString("en-US", {
                            year: 'numeric',
                            month: '2-digit',
                            day: 'numeric',
                          })} */}
                          {/* moment() */}
                        </Text>
                      </SkeletonText>
                    )
                  } else if (cell.column.Header === "MANAGE") {
                    data = (
                      <Flex dir="row">
                        <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                          <Button onClick={(e) => {
                            routeChangeView(cell.value)
                          }
                          } color={textColor} fontSize='sm' fontWeight='700'>
                            View Game
                          </Button>
                        </SkeletonText>
                        <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                          <Button onClick={(e) => {
                            routeChangeEdit(cell.value)
                          }
                          } color={textColor} fontSize='sm' fontWeight='700'>
                            Edit Game
                          </Button>
                        </SkeletonText>
                        <SkeletonText noOfLines={1} fadeDuration={1} isLoaded={!loading}>
                          <Button onClick={async(e) => {
                            deleteGame(cell.value)
                          }
                          } color={textColor} fontSize='sm' fontWeight='700'>
                            Delete Game
                          </Button>
                        </SkeletonText>
                      </Flex>
                    )
                  }

                  return (

                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}

                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex dir="row" gap={4} mx={8}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <Icon as={FaAngleDoubleLeft} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <Icon as={FaAngleLeft} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <Icon as={FaAngleRight} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <Icon as={FaAngleDoubleRight} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <Flex grow={true} w={"46vw"}>

        </Flex>
        <select
          value={pageSize}

          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Flex>
    </Card>
  );
}
