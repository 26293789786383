import { Box, Flex, SimpleGrid, Skeleton, Spinner, Text } from "@chakra-ui/react";

import React, { useEffect } from "react";
import { IoConstructOutline } from "react-icons/io5";
import ViewGame from "./components/viewGame";
import LocationViewCard from "../viewLocation/components/viewLocation";
import apiHelper from "helpers/apiHelper";
import { useLocation, useParams } from "react-router-dom";

export default function ViewLocation(props) {
  const [res, setRes] = React.useState({})
  const [location, setLocation] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const { setToken, token, setBrand } = props
  const { id } = useParams();
  // const id = props.history?.location?.state?.id || 

  useEffect(() => {
    setBrand(res.name)
    if (id) {
      apiHelper.getGame(id).then((resp) => {
        console.log(resp)
        setRes(resp)
        apiHelper.getLocation(resp.league_location).then((resp) => {
          setLocation(resp)
        setLoading(false)
        })
      }).catch((e) => {
        setLoading(false)
      })
    }
  }, [])
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {id && !loading ?

        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <ViewGame
            game={res}
            loading={loading}
          />
          <LocationViewCard
            data={location}
            loading={loading}
          />

        </SimpleGrid>
        :
        <Flex
          w={"100%"}
          h={"full"}
          position={"absolute"}
          bottom={10}
          alignItems={"center"}
          justifyContent={"center"}>
          <Flex padding={4} backgroundColor={"white"}
            alignItems={"center"}
            justifyContent={"center"}
            w={24}
            h={24}

            borderRadius={"full"}>
            <Spinner size={"xl"} color={"brand.600"} borderWidth={4} />
          </Flex>
        </Flex>
      }
    </Box>
  );
}
