// Chakra imports
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { HerdOfZebrasLogo } from "components/icons/Icons";
import PropTypes from "prop-types";
import React from "react";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;

  // Chakra color mode
  return (
    <Flex position='relative' h='max-content'>
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w='100%'
        maxW={{ md: "66%", lg: "1313px" }}
        mx='auto'
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent='start'
        direction='column'>
        {children}
        <Box
          display={{ base: "none", md: "block" }}
          h='100%'
          minH='100vh'
          w={{ lg: "50vw", "2xl": "44vw" }}
          position='absolute'
          right='0px'>
          <Flex
            bg={`url(${illustrationBackground})`}
            display={{ base: "none", md: "flex" }}
            justify='center'
            align='center'
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            direction={"column"}
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}>
            <Flex
              w={"70%"}
              h={"45%"}
              bg='white'
              padding={"20px"}
              borderRadius={"10px"}
              direction={"column"}
            >
              <Text
                fontSize={"xl"}
              >
                <Heading color={"gray.300"} fontSize='36px' mb='10px'>
                  <HerdOfZebrasLogo w="300px" h="100px" />
                </Heading>
                Enter the referees you need, choose who you want and schedule. We’ll notify you when they check in for games and send scores and penalties from the field. Only pay for the referees you use.

              </Text>
              <Button
                colorScheme={"brand.400"}
                mt={10}
                w={"40%"}
                h={"50px"}
                borderRadius={"full"}
                bg={"brand.600"}
                _hover={{
                  bg: "brand.700",
                }}
                _active={{
                  bg: "brand.700",
                }}
                onClick={() => {
                  window.open("https://www.herdofzebras.com", "_blank");
                }}
              >
                Learn More
              </Button>
            </Flex>

          </Flex>
        </Box>
      </Flex >
      <Flex position={"absolute"} right={10} bottom={0}>
        <Text color={"gray.400"}>
          v2.0.3
        </Text>
      </Flex>
    </Flex >
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
