import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    var userToken
    try {
      const tokenString = localStorage.getItem('access_token');
      userToken = tokenString;
    } catch (e) {
      userToken = null
    }
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('access_token', userToken);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}