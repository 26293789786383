import axios from "axios";

export const AuthenticatedRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

AuthenticatedRequest.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

AuthenticatedRequest.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/token/" && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    let refresh = await localStorage.getItem("refresh_token");
                    const rs = await AuthenticatedRequest.post("/token/refresh/", {
                        refresh: refresh,
                    }).catch(async (err) => {
                        await localStorage.removeItem("access_token");
                        await localStorage.removeItem("refresh_token");
                        return Promise.reject(err);
                    });
                    await localStorage.setItem("access_token", rs.data.access);
                    return AuthenticatedRequest(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);
