export const round2 = (value) => {
    let floatValue = parseFloat(value);

    // Ensure the parsing was successful before trying to call `toFixed`
    if (!isNaN(floatValue)) {
        floatValue = floatValue.toFixed(2);
        return parseFloat(floatValue);
    } else {
        console.error("Cannot parse the value to a float");
    }    
}