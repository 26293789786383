import React from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import LeagueLayout from "layouts/league";


import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
createRoot(container).render(
<>
  <ChakraProvider theme={theme}>
    <ThemeEditorProvider>
      <CookiesProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/league`} component={LeagueLayout} />
            <Redirect from='/' to='/auth' />
          </Switch>
        </HashRouter>
      </CookiesProvider>
    </ThemeEditorProvider>
  </ChakraProvider>

</>);