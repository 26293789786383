import { Box, Flex, Grid, GridItem, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import apiHelper from "helpers/apiHelper";
import React, { useMemo } from "react";
import HOZRefCard from "./HOZRefCard";



export default function ViewGame(props) {
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const duration = 30
    const { game, loading } = props
    const [refs, setRefs] = React.useState([])

    const getGameNotes = (refs) => {
        const result = refs?.map((ref) => {
            return `${ref?.name}/${game.team_one_name}: ${ref?.team_one_score}/${game.team_two_name}: ${ref?.team_two_score}`
        })?.join(", ")
        if (result) {
            return result
        } else {
            return ''
        }
    }

    React.useEffect(() => {
        if (game) {
            apiHelper.getGamePositions(game.id).then(res => {
                setRefs(res)
            })
        }
    }, [game])
    // EXPORT ISNT WORKING SO......
    const sportTypes = [
        { id: 1, sport: 'Soccer', icon: "football-outline" },
        { id: 2, sport: 'Lacrosse', icon: "true" },
        { id: 3, sport: 'Football', icon: "american-football-outline" },
        { id: 4, sport: 'Baseball', icon: "baseball-outline" },
        { id: 5, sport: 'Basketball', icon: "basketball-outline" },
        { id: 6, sport: 'Volleyball', icon: "true" },
        { id: 7, sport: 'Hockey', icon: "true" },
        { id: 8, sport: 'Softball', icon: "true" },
        { id: 9, sport: 'Rugby', icon: "true" },
        { id: 10, sport: 'Tennis', icon: "tennisball-outline" },
    ]
    return (
        <div>
            <Card>
                <Flex justify='space-between' px={"22px"} mb='20px' align='center'>
                    <Text
                        color={textColorPrimary}
                        fontSize='34px'
                        fontWeight='700'
                        lineHeight='100%'>
                        Game Info
                    </Text>
                </Flex>
                <Grid
                    px='22px'
                    mb='20px'
                    templateColumns={{
                        base: "1fr",
                        lg: "repeat(2, 1fr)",
                        "2xl": "1.34fr 1.62fr 1fr",
                    }}
                    templateRows={{
                        base: "1fr",
                        lg: "repeat(2, 1fr)",
                        "2xl": "1fr",
                    }}>

                    <Box justifySelf="flex-start">
                        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                            {game.name}
                        </Text>
                        <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px'>
                            {game.team_one_name} Vs. {game.team_two_name}
                        </Text>
                    </Box>

                    <Box justifySelf="flex-end">
                        <Text color={textColorSecondary} fontWeight='medium' fontSize='md' mt='10px' mb='4px'>
                            {
                                sportTypes.find(sport => sport.id === game.sport)?.sport
                            }
                        </Text>
                    </Box>
                    <Box gridArea={"2/1/2/3"}>
                        <Flex color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px' flexDirection={"row"} >
                            <Text flexGrow pr={4}>Start Time:</Text>

                            <Text color={textColorSecondary}>
                                {
                                    new Date(`${game.game_date} ${game.game_start_time}`).toLocaleDateString("en-US", {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true

                                    })
                                }
                            </Text>
                        </Flex>
                        <Flex justifyContent={"flex-start"} color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px' flexDirection={"row"} >
                            <Text pr={4}>End Time:</Text>

                            <Text color={textColorSecondary}>
                                {
                                    new Date(`${game.game_date} ${game.game_end_time}`).toLocaleDateString("en-US", {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true

                                    })
                                }
                            </Text>
                        </Flex>

                    </Box>
                    {/* <Text gridArea={{ base: "2 / 2 / 2 / 2", lg: "2 / 2 / 2 / 2" }}
    color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
    Referees:
  </Text> */}
                    {refs.map((position, i) => {
                        return <HOZRefCard position={position} index={i} key={i}></HOZRefCard>
                    })}
                </Grid>
            </Card>
            <Card mt={5}>
                <Flex dir="row">
                    <Card>
                        <Flex justify='space-between' px={"22px"} mb='20px' align='center'>
                            <Text
                                color={textColorPrimary}
                                fontSize='34px'
                                fontWeight='700'
                                lineHeight='100%'>
                                Game Rules
                            </Text>
                        </Flex>
                        <Text px={"22px"}>
                            {game.rules}
                        </Text>
                    </Card>
                    <Card>
                        <Flex justify='space-between' px={"22px"} mb='20px' align='center'>
                            <Text
                                color={textColorPrimary}
                                fontSize='34px'
                                fontWeight='700'
                                lineHeight='100%'>
                                Game Scores
                            </Text>
                        </Flex>
                        <Text px={"22px"}>
                            {getGameNotes(refs)}
                        </Text>
                    </Card>
                    <Card>
                        <Flex justify='space-between' px={"22px"} mb='20px' align='center'>
                            <Text
                                color={textColorPrimary}
                                fontSize='34px'
                                fontWeight='700'
                                lineHeight='100%'>
                                Game Notes
                            </Text>
                        </Flex>
                        <Text px={"22px"}>
                            {game.notes}
                        </Text>
                    </Card>
                </Flex>
            </Card>
        </div>
    )
}