import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import PropTypes from "prop-types";
import { HerdOfZebrasLogo } from "components/icons/Icons";
import { HOZShortLogo } from "components/icons/Icons";
import SignInPage from "./components/SignIn";
import CreateLeaguePage from "./components/createLeague";

function SignIn(props) {

  const [auth, setAuth] = React.useState("")
  const [create, setCreate] = React.useState(false)




  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const googleBg = useColorModeValue("white", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const history = useHistory();

  const { setToken, token, setBrand } = props

  return (
    <DefaultAuth illustrationBackground={illustration} >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "4vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            <HerdOfZebrasLogo w="400px" h="200px" />
          </Heading>
          {
            auth === "" ? (

              <Text
                mb='36px'
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'>
                Enter your email and password to sign in!
              </Text>
            ) : ""
          }
        </Box>

        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>


          {
            create ? <CreateLeaguePage setCreate={setCreate} create={create} /> :
              <SignInPage setCreate={setCreate} create={create} />
          }

          {
            auth === "" ? (
              <Box>
                <Flex align='center' mb='25px'>




                  <HSeparator />
                  <Text width={"200%"} color='gray.400' mx='20px'>
                    dont have an account?
                  </Text>
                  <HSeparator />

                </Flex>



                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='start'
                  maxW='100%'
                  w={"100%"}
                  mt='0px'>
                  <Button
                    w='100%'
                    fontSize='sm'
                    me='0px'
                    mb='26px'
                    py='15px'
                    h='50px'
                    borderRadius='16px'
                    bg={googleBg}
                    color={googleText}
                    fontWeight='500'
                    _hover={googleHover}
                    _active={googleActive}
                    onClick={async (e) => {
                      history.push("/auth/signup");

                    }}
                    _focus={googleActive}>
                    <HOZShortLogo h="30px" w="30px" me='4px' />
                    Create An Account
                  </Button>
                </Flex>
              </Box>
            ) : ""
          }
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}
SignIn.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func,
};

export default SignIn;
