// Chakra imports
import { Box, Flex, Grid, Icon, Image, Input, Text, Textarea, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import banner from "assets/img/auth/banner.png";
import Map, { Layer, Feature, Source } from 'react-map-gl';
import { useCallback } from "react";
import { MdInfoOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";

const TOKEN = 'pk.eyJ1IjoiY2hpY2tlbmJvbmUiLCJhIjoiY2w5NGpwNHFvMGV4ajQxb3pycDVqN2p3aSJ9.6TzyXEH9osCQ31r5-d3XqQ';

export default function HOZFieldView(props) {
    const { title, index, startLat, startLon } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const bg = useColorModeValue("white", "navy.700");
    const bgItem = useColorModeValue(
        { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
        { bg: "navy.700", boxShadow: "unset" }
    );
    return (
        <Grid
            mb='10px'
            templateColumns={{
                base: "1fr",
                lg: "repeat(1, 1fr)",
            }}
            px='22px'
            gap={{ base: "10px", xl: "10px" }}
            backgroundColor={"white"}
            borderRadius={16}>
            < Flex flexDirection={"column"} >
                <Flex py={5} flexDirection={"column"}>
                    <Flex justify='space-between' mb='20px' align='center'>
                        <Text
                            color={textColorPrimary}
                            fontSize='34px'
                            fontWeight='700'
                            lineHeight='100%'>
                            {title}
                        </Text>
                    </Flex>
        
                </Flex>
            </Flex >
            <Box justifySelf={"flex-end"} w="100%" p={0} h="400px" overflow={"hidden !important"} alignItems={"center"} justifyContent={"center"} borderRadius={"20"} bg={"gray"}>
                <Map
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        WebkitClipPath: "inset(0% 0% 0% 0% round 10px)"

                    }}
                    initialViewState={{
                        longitude: startLon,
                        latitude: startLat,
                        zoom: 16
                    }}
                    mapStyle="mapbox://styles/mapbox/satellite-v9"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    onIdle={(event) => event.target.resize()}
                    onRender={(event) => event.target.resize()}

                >
                    <Source type="geojson" data={{
                        "type": "FeatureCollection",
                        "features": [
                            {
                                "type": "Feature",
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [startLon, startLat]
                                }
                            }
                        ]
                    }}>
                        <Layer {...{
                            id: 'data',
                            type: 'fill',
                            paint: {
                                'fill-color': "#e8602a",
                                'fill-opacity': 0.5
                            }
                        }} />
                    </Source>
                </Map>
            </Box>
        </Grid >

    )
}