import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Grid,
  Text,
  Button,
  Input,
  Textarea,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Card from "components/card/Card.js";
import Game from "components/card/Game";
import HOZRefCard from "./components/HOZRefCard";
import { Select } from '@chakra-ui/react'
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Calendar } from "react-calendar";
import { useHistory, useParams } from "react-router-dom";
import "../../../assets/css/MiniCalendar.css";
import apiHelper from "helpers/apiHelper";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker";
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css'
import moment from "moment";

export default function EditGame(props) {
  var { token, setToken, setBrand } = props
  const toast = useToast()

  const { id } = useParams();

  useEffect(() => {
    setBrand("Game")
  }, [])

  const sportTypes = [
    { id: 1, sport: 'Soccer', icon: "football-outline" },
    { id: 2, sport: 'Lacrosse', icon: "true" },
    { id: 3, sport: 'Football', icon: "american-football-outline" },
    { id: 4, sport: 'Baseball', icon: "baseball-outline" },
    { id: 5, sport: 'Basketball', icon: "basketball-outline" },
    { id: 6, sport: 'Volleyball', icon: "true" },
    { id: 7, sport: 'Hockey', icon: "true" },
    { id: 8, sport: 'Softball', icon: "true" },
    { id: 9, sport: 'Rugby', icon: "true" },
    { id: 10, sport: 'Tennis', icon: "tennisball-outline" },
  ]
  const gameObj = {
    "name": null,
    "league_location": null,
    "game_type": null,
    "status": 1,
    "rules": null,
    "notes": null,
    "game_start_time": null,
    "game_end_time": null,
    "game_date": null,
    "team_one_name": null,
    "team_two_name": null,
    "positions": []
  }
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const [next, setNext] = useState(Number(0));
  const [game, setGame] = useState(gameObj);

  const [date, setDate] = useState();
  const [duration, setDuration] = useState(30);

  // const [hours, setHours] = useState(date.getHours());
  // const [mins, setMins] = useState(date.getMinutes());
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState([])
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        const leagueLocations = await apiHelper.getLeagueLocations();
        setRes(leagueLocations)
        if (id) {
          const game = await apiHelper.getGame(id);
          const positions = await apiHelper.getGamePositions(id);
          setGame({
            ...game,
            positions
          })
        }
      } catch(ex) {

      } finally {
        setLoading(false)
      }
    }    
    // if (id) {
    //   setLoading(true)
    //   apiHelper.getLeagueLocations().then((resp) => {
    //     apiHelper.getGame(id).then((game) => {
    //       apiHelper.getGamePositions(game.id).then((positions) => {
    //         setGame({
    //           ...game,
    //           positions
    //         })
    //         setLoading(false)
    //       })
    //     })
    //     setRes(resp)
    //   }).catch((e) => {
    //   })
    // } else {
    //   setGame({
    //     ...gameObj
    //   })
    // }
    load()
  }, [])
  const history = useHistory();
  const routeChange = () => {
    let path = `/league/games`;
    history.push(path);
  }

  const trimSeconds = (time) => {
    const result = time?.substr(0, 5);
    return result;
  }

  useEffect(() => {
    setTimeRange([trimSeconds(game?.game_start_time), trimSeconds(game?.game_end_time)])
    if (game.game_date) {
      setDate(game.game_date)
    }
  }, [game.game_start_time, game.game_end_time, game.game_date])
  

  const [timeRange, setTimeRange] = useState(['9:00', '11:00'])
  
  const onTimeChange = (values) => {
    setTimeRange(values);
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} >
      {
        !loading ?
          <Grid
            mb='20px'
            templateColumns={{
              base: "1fr",
              lg: "repeat(3, 1fr)",
            }}
            templateRows={{
              base: "repeat(2, 1fr)",
              "2xl": "1fr",
              "2xl": "1fr",
            }}
            gap={{ base: "10px", xl: "10px" }}>
            <Flex
              flexDirection='column'
              gridArea={{
                base: "2 / 1 / 2 / 3",
                lg: "1 / 3 / 3 / 3",
              }}
              justify='space-between'

            >
              <Card px='0px' mb='20'>
                <Flex
                  align={{ sm: "flex-start", lg: "center" }}
                  justify='space-between'
                  w='100%'
                  px='22px'
                >
                  <Text color={textColorPrimary} fontSize='xl' fontWeight='600'>
                    Select Date
                  </Text>
                </Flex>
                <Flex
                  align={{ sm: "center", lg: "center" }}
                  justify="center"
                  w='100%'
                  px='22px'
                  py='18px'>

                  <Calendar
                    onChange={(e) => {
                      setDate(e);
                      setDate(new Date(e))
                    }}
                    value={date ? moment(date).toDate(): moment().toDate()}
                    view={"month"}
                    back
                    tileContent={<Text color='brand.200'></Text>}
                    prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
                    nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
                  />
                </Flex>
                <Flex
                  align={{ sm: "center", lg: "center" }}
                  justify="space-evenly"
                  w='90%'
                  px='22px'
                  py='18px'
                  flexDirection={"column"}
                >
                <div>
                  <TimeRangePicker onChange={onTimeChange} value={timeRange} disableClock={true}/>
                </div>                  

                </Flex>
              </Card>
            </Flex>
            <Card
              gridArea={{
                base: "1 / 1 / 2 / 3",
              }}
              px='22px'
              pb='24'
              mb={{ base: "0px", "2xl": "20px" }}>

              <div
                transition='0.2s linear'
                hidden={next > 0}>

                <Flex >
                  <Text
                    color={textColorPrimary}
                    fontSize={{
                      base: "2xl",
                      md: "2xl",
                      lg: "2xl",
                      xl: "2xl",
                      "2xl": "md",
                      "3xl": "lg",
                    }} mb='5px' mt="30" fontWeight='bold' wordBreak={"break-word"} me='14px'>General Info</Text>
                </Flex>
                <Grid
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                    "2xl": "1.34fr 1.62fr 1fr",
                  }}
                  templateRows={{
                    base: "1fr",
                    lg: "repeat(1, 1fr)",
                    "2xl": "1fr",
                  }}
                  gap={{ base: "10px", xl: "10px" }}>
                  <Box>
                    <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                      Game Name
                    </Text>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                      <Input placeholder={"Game Name"} value={game.name} borderRadius="16px"
                        onChange={
                          (e) => {
                            setGame((ps) => {
                              let update = { ...ps }
                              update.name = e.target.value
                              return { ...update }
                            })
                          }}
                      />
                    </Text>
                  </Box>
                  <Flex direction={"row"} gap={8}>
                    <Box>
                      <Text fontWeight='500' color={textColorSecondary} value={game.gameSport} fontSize='sm'>
                        Game Type
                      </Text>
                      <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                        <Select w={"22rem"} value={game.game_type} borderRadius="16px" onChange={
                          (e) => {
                            setGame((ps) => {
                              let update = { ...ps }
                              update.game_type = e.target.value
                              return { ...update }
                            })
                          }} >
                          <option selected>
                            Select
                          </option>
                          {
                            sportTypes.map((sport) => {
                              return <option value={sport.id}>{sport.sport}</option>
                            })
                          }
                        </Select>
                      </Text>
                    </Box>
                  </Flex>

                  <Box>
                    <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                      Home Team
                    </Text>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                      <Input placeholder={"Home Team"} value={game.team_one_name} borderRadius="16px"
                        onChange={(e) => {
                          setGame((ps) => {
                            let update = { ...ps }
                            update.team_one_name = e.target.value
                            return { ...update }
                          })
                        }} />
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                      Away Team
                    </Text>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                      <Input placeholder={"Away Team"} value={game.team_two_name} borderRadius="16px"
                        onChange={(e) => {
                          setGame((ps) => {
                            let update = { ...ps }
                            update.team_two_name = e.target.value
                            return { ...update }
                          })
                        }} />
                    </Text>
                  </Box>
                </Grid>
                <Box>
                  <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                    Field
                  </Text>
                  <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                    <Select borderRadius="16px" value={game.league_location} onChange={
                      (e) => {
                        setGame((ps) => {
                          let update = { ...ps }
                          update.league_location = e.target.value
                          return update
                        })
                      }}>
                      <option selected>
                        Select
                      </option>
                      {res.map(loc => (
                        <option key={loc.id} value={loc.id} id={loc.id}>
                          {loc.name}
                        </option>
                      ))}
                    </Select>
                  </Text>

                </Box>
              </div>
              <div hidden={!(next == 1)}>
                <Text
                  color={textColorPrimary}
                  fontSize={{
                    base: "2xl",
                    md: "2xl",
                    lg: "2xl",
                    xl: "2xl",
                    "2xl": "md",
                    "3xl": "lg",
                  }} px='22px' pb='20px' mt="30px" mb='10px' fontWeight='bold' wordBreak={"break-word"} >Game Details</Text>
                <Flex px='22px' pb='20px' flexDir={"row"} >
                  <Box w={"50%"} mr={"10%"} flexGrow={true} >
                    <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                      Game Rules
                    </Text>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                      <Textarea value={game.rules} borderRadius="16px" placeholder="Field notes" fontSize='md'
                        onChange={
                          (e) => {
                            setGame((ps) => {
                              let update = { ...ps }
                              update.rules = e.target.value
                              return update
                            })
                          }}></Textarea>
                    </Text>
                  </Box>
                  <Box w={"40%"} flexGrow={true}>
                    <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                      Game Extras (Optional)
                    </Text>
                    <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                      <Textarea value={game.notes} borderRadius="16px" placeholder="Field notes" fontSize='md'
                        onChange={
                          (e) => {
                            setGame((ps) => {
                              let update = { ...ps }
                              update.notes = e.target.value
                              return update
                            })
                          }}></Textarea>
                    </Text>
                  </Box>
                </Flex>
                <Flex align={{ sm: "flex-start", lg: "center" }} justify='space-between' w='100%' px='22px' pb='20px' mb='10px'>
                  <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                    Referees
                  </Text>
                  <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>

                    <Button
                      position='absolute'
                      p='0px !important'
                      right='14px'
                      borderRadius='50%'
                      minW='36px'
                      h='36px'
                      onClick={() => {

                        setGame((ps) => {
                          let update = { ...ps }
                          update.positions = [...update.positions, {
                            name: "Center Ref",
                            pay_rate: 0
                          }]
                          return update
                        })
                      }}>
                      <Icon as={FaPlus} fill="brand.600" color={textColorPrimary} transition='0.2s linear' w='20px' h='20px' />
                    </Button>
                  </Box>
                </Flex>
                <Grid
                  px='22px'
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                    "2xl": "1.34fr 1.62fr 1fr",
                  }}
                  templateRows={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                    "2xl": "1fr",
                  }}>

                  {game.positions.map((ref, i) => {
                    return <HOZRefCard get={game} set={setGame} index={i} ></HOZRefCard>
                  })}

                </Grid>
              </div>
              <div hidden={!(next == 2)}>
                <Flex align={{ sm: "flex-start", lg: "center" }} justify='space-between' w='100%' px='22px' pb='20px' mb='10px'>
                  <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                    Finalize Game
                  </Text>
                </Flex>
                <Grid
                  px='22px'
                  mb='20px'
                  templateColumns={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                    "2xl": "1.34fr 1.62fr 1fr",
                  }}
                  templateRows={{
                    base: "1fr",
                    lg: "repeat(2, 1fr)",
                    "2xl": "1fr",
                  }}>
                  <Box justifySelf="flex-start">
                    <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                      {game.name}
                    </Text>
                    <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px'>
                      {game.team_one_name} Vs. {game.team_two_name}
                    </Text>
                  </Box>

                  <Box justifySelf="flex-end">
                    <Text color={textColorSecondary} fontWeight='medium' fontSize='md' mt='10px' mb='4px'>
                      {sportTypes.filter((s) => s.id == game.game_type)?.sport}
                    </Text>
                  </Box>
                  {game.positions.map((ref, i) => {
                    return <HOZRefCard get={game} index={i}></HOZRefCard>
                  })}
                </Grid>
              </div>
              <Flex
                flexDirection='row'
                w="100%"
                justify='flex-end'
                my="5"
                position={"absolute"}
                bottom={0}
                right="10"
              >
                {next > 0 ?
                  <Button w="40" h="50" variant="active" onClick={() => {
                    setNext(next - 1);
                  }}>Previous</Button>
                  : ""}
                {next <= 1 ?
                  <Button w="40" h="50" variant="brand" onClick={() => {
                    setNext(next + 1);
                  }}>Next</Button>
                  : next == 2 ?
                    <Button id="page2" key="page2" disabled={loading} w="40" h="50" variant="brand" onClick={async () => {
                      if (!game.name || !game.team_one_name || !game.team_two_name || !game.game_type || !game.positions.length) {
                        toast({
                          title: "Error",
                          description: "Please fill out all fields",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        })
                        return
                      } else {
                        await setGame((prevstate) => {
                          let update = prevstate
                          update.game_date = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
                          update.game_start_time = timeRange[0];
                          update.game_end_time = timeRange[1];
                          return update
                        })
                        try {
                          let res = {}
                          if (game.id) {
                            res = await apiHelper.updateGame(game.id, game)
                          } else {
                            res = await apiHelper.createGame(game)
                          }
                          console.log({ gameUpdate: res })
                          await game.positions.map(async (position, i) => {
                            if (position.id) {
                              let resp = await apiHelper.updatePosition(position.id, position)
                              console.log({ patch: resp })
                            } else {
                              let resp = await apiHelper.createPosition({
                                game: res.id,
                                name: position.name,
                                pay_rate: position.pay_rate,
                                status: 1
                              })
                              console.log({ post: resp })

                            }
                          })
                          toast({
                            title: "Game Created",
                            description: "Your game has been created",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          })
                          history.push("/dashboard")
                        } catch (e) {
                          console.log(e)
                          toast({
                            title: "Error",
                            description: "There was an error creating your game",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          })
                        }
                      }
                    }}>              {
                        loading ?
                          <Spinner color='white' />
                          :
                          <Text color={"white"}>Save</Text>
                      }</Button>
                    : ""
                }
              </Flex>

            </Card>
          </Grid>
          :
          <Flex
            w={"100%"}
            h={"full"}
            position={"absolute"}
            bottom={10}
            alignItems={"center"}
            justifyContent={"center"}>
            <Flex padding={4} backgroundColor={"white"}
              alignItems={"center"}
              justifyContent={"center"}
              w={24}
              h={24}

              borderRadius={"full"}>
              <Spinner size={"xl"} color={"brand.600"} borderWidth={4} />
            </Flex>
          </Flex>
      }
    </Box>
  );
}
