import { Box, Flex, GridItem, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useMemo } from "react";
import HOZFieldView from "./HOZFieldView";


export default function ViewLocation(props) {
    const { data } = props

    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    return (
        <SimpleGrid
            direction='column'
            w='100%'
            overflowX={{ sm: "scroll", lg: "hidden" }}
            justify='space-between' mb='20px'
            gap={4}
            columns={{ base: 1, md: 2, lg: 3 }}

        >
            <GridItem colSpan={2}>
                <HOZFieldView title={data.name} startLat={data.latitude} startLon={data.longitude} />
            </GridItem>
            <GridItem colSpan={1}>
                <Card>
                    <Text
                        fontWeight='bold'
                        fontSize='2xl'
                        mb={5}>{data.name}</Text>
                    <Text
                        fontSize='sm'
                        mb={5}>{data.city}, {data.state}</Text>
                </Card>
            </GridItem>
        </SimpleGrid>
    )
}