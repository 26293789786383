import React from "react";

// Chakra imports
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import HOZText from "components/text/HOZText";
import { RiEyeCloseLine } from "react-icons/ri";
import { NavLink, useHistory } from "react-router-dom";
import apiHelper from "helpers/apiHelper";

const INIT_LEAGUE = {
  name: ''
}

export default function CreateLeaguePage(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const handleClick = () => setShow(!show);
  const history = useHistory()

  const routeChange = () => {
    let path = `/league/default`;
    history.push({
      pathname: path,
    });
    window.location.reload()
  }
  const {
    create,
    setCreate,
    ...rest } = props;

  const [show, setShow] = React.useState(false)
  const [league, setLeague] = React.useState(INIT_LEAGUE)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)





  return (
    <Box>
      <Text fontSize='2xl' fontWeight='500' color={textColor}>
        Create a League
      </Text>
      <Text fontSize='md' color={textColorSecondary} mb={10}>
        You currently have no leagues. Create a league to get started.
      </Text>
      <FormLabel
        display='flex'
        ms='4px'
        fontSize='sm'
        fontWeight='500'
        color={textColor}
        mb='8px'>
        League Name<Text color={brandStars}>*</Text>
      </FormLabel>
      <Input
        bg={useColorModeValue("white", "gray.700")}
        isRequired={true}
        variant='auth'
        fontSize='sm'
        ms={{ base: "0px", md: "0px" }}
        type='email'
        placeholder='League Name'
        mb='24px'
        fontWeight='500'
        size='lg'
        value={league.name}
        onChange={(e) => setLeague({...league, name: e.target.value})}
      />
      {
        error != "false" ?
          <HOZText mb={5} color={"brand.500"}>{error}</HOZText>
          :
          <HOZText></HOZText>
      }
      <Button
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        _hover={{ bg: "brand.600" }}
        w='100%'
        h='50'
        mb='24px'
        disabled={loading}
        onClick={async (e) => {
          setLoading(true)
          try {
            let resp = await apiHelper.createLeague(league)
            if (resp) {
              routeChange()
            }
          } catch (e) {
            console.log(e)
            setError("Unable to create league. Please try again.")
          }
          setLoading(false)


        }}>
        {
          loading ?
            <Spinner color='white' />
            :
            <HOZText color="white">Create league</HOZText>
        }
      </Button>
    </Box>
  )
}