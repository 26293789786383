import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdSettings,
} from "react-icons/md";

// Admin Imports
import ManageGames from "views/league/manageGames";
import ManageLocations from "views/league/manageLocations";
import ManageBilling from "views/league/manageBilling";



// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";

import Logout from "views/auth/logout";

import CreateGame from "views/league/createGame";
import CreateField from "views/league/createField";
import { FaFutbol, FaMapMarker, FaTasks, FaCreditCard } from "react-icons/fa";
import ViewLocation from "views/league/viewLocation";
import ViewGame from "views/league/viewGame";
import EditGame from "views/league/editGame";
import UserReviewForm from "views/league/user/UserReviewForm";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/signin",
    basePath: "/signin",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    show: false,

  },
  {
    name: "Sign Up",
    type: "Auth",
    layout: "/auth",
    path: "/signup",
    basePath: "/signup",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
    show: false,
  },
  {
    name: "Logout",
    type: "Auth",
    layout: "/auth",
    path: "/logout",
    basePath: "/logout",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Logout,
    show: false,
  },
  {
    name: "Manage Games",
    type: "Manage",
    layout: "/league",
    path: "/default",
    basePath: "/default",
    icon: <Icon as={FaFutbol} width='20px' height='20px' color='inherit' />,
    component: ManageGames,
    show: true,
  },
  {
    name: "Edit Game",
    type: "Manage",
    layout: "/league",
    path: "/editgame/:id",
    basePath: "/editgame",
    icon: <Icon as={FaFutbol} width='20px' height='20px' color='inherit' />,
    component: EditGame,
    show: false,
  },
  {
    name: "View Location",
    type: "View",
    layout: "/league",
    path: "/location/view/:id",
    basePath: "/location/view",
    icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,
    component: ViewLocation,
    show: false,

  },
  {
    name: "View Game",
    type: "View",
    layout: "/league",
    path: "/game/view/:id",
    basePath: "/game/view",
    icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,
    component: ViewGame,
    show: false,

  },
  {
    name: "User Profile",
    type: "View",
    layout: "/league",
    path: "/userreview/:userPositionId",
    basePath: "/userreview",
    icon: <Icon as={FaTasks} width='20px' height='20px' color='inherit' />,
    component: UserReviewForm,
    show: false,

  },
  {
    name: "Manage Locations",
    type: "Manage",
    layout: "/league",
    path: "/location",
    basePath: "/location",
    icon: <Icon as={FaMapMarker} width='20px' height='20px' color='inherit' />,
    component: ManageLocations,
    show: true,

  },
  {
    name: "Manage Billing",
    type: "Manage",
    layout: "/league",
    path: "/billing",
    basePath: "/billing",
    icon: <Icon as={FaCreditCard} width='20px' height='20px' color='inherit' />,
    component: ManageBilling,
    show: true,
  },
  {
    layout: "/league",
    name: "Create Game",
    type: "Create",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    path: "/creategame",
    basePath: "/creategame",
    component: EditGame,
    show: false,

  },
  {
    layout: "/league",
    name: "Create Location",
    type: "Create",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    path: "/createfield",
    basePath: "/createfield",
    component: CreateField,
    show: false,

  },
];

export default routes;
