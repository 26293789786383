import React from "react";

// Chakra imports
import { Flex, FormLabel, Input, InputGroup, Text, useColorModeValue } from "@chakra-ui/react";
import HOZText from "components/text/HOZText";




export default function AuthStep3(props) {

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");

    const {
        auth,
        setAuth,
        error,
        ...rest } = props;

    return (
        <Flex direction={"column"}>
            <Flex direction={"row"} gap={4}>
                <Flex direction={"column"}>
                    <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        First Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size='md'>

                        <Input
                            isRequired={true}
                            fontSize='sm'
                            placeholder='John'
                            mb='24px'
                            size='lg'
                            type={"text"}
                            autoComplete={"given-name"}
                            variant='auth'
                            value={auth.fname}
                            onChange={(e) => {
                                setAuth((ps) => {
                                    let update = { ...ps }
                                    update.fname = e.target.value
                                    return { ...update }
                                })
                            }
                            }
                        />
                    </InputGroup>
                </Flex>
                <Flex direction={"column"}>

                    <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Last Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size='md'>

                        <Input
                            isRequired={true}
                            fontSize='sm'
                            placeholder='Smith'
                            mb='24px'
                            size='lg'
                            type={"text"}
                            variant='auth'
                            value={auth.lname}
                            autoComplete={"family-name"}
                            onChange={(e) => {
                                setAuth((ps) => {
                                    let update = { ...ps }
                                    update.lname = e.target.value
                                    return { ...update }
                                })
                            }}
                        />
                    </InputGroup>
                </Flex>
            </Flex>
            <Flex direction={"column"}>
                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Phone Number<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='(202) 202-3333'
                    mb='24px'
                    autoComplete={"tel-national"}
                    fontWeight='500'
                    size='lg'
                    value={auth.pNum}
                    onChange={(e) => {
                        setAuth((ps) => {
                            let update = { ...ps }
                            update.pNum = e.target.value
                            return { ...update }
                        })
                    }}
                />
            </Flex>

            {
                error != "false" ?
                    <HOZText mb={5} color={"brand.500"}>{error}</HOZText>
                    :
                    <HOZText color="white"></HOZText>
            }
        </Flex>
    );
}

