const { Text, useColorModeValue } = require("@chakra-ui/react");

function HOZText(props) {
    const { color, variant, children, loading, ...rest } = props;
    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.800");

    return (
        <Text {...rest}
            color={color ? color : textColor}
            cursor='default'>
            {children}
        </Text>
    );
}
export default HOZText;
