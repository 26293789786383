import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Grid,
  Text,
  Button,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
// Custom components
import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
// Custom components
import Card from "components/card/Card.js";
import { useState } from "react";
import HOZFieldCreate from "./components/HOZFieldCreate";
import { Spinner } from '@chakra-ui/react'
import { useHistory } from "react-router-dom";
import HOZFieldView from "./components/HOZFieldView";
import HOZText from "components/text/HOZText";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import apiHelper from "helpers/apiHelper";

export default function CreateLocation(props) {
  const toast = useToast()

  var { token, setBrand } = props
  useEffect(() => {
    setBrand("Location")
  }, [])
  


  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const [next, setNext] = useState(Number(0));
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(
    {
      name: "",
      notes: "",
      address1: "",
      location: "",
      city: "",
      state: "",
      zip: "",
      latitude: "",
      longitude: "",
      status: 1
    }
  )
  const history = useHistory();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(3, 1fr)",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(3, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "10px", xl: "10px" }}>
        <Card
          gridArea={{
            base: "1 / 1 / 4 / 4",
          }}
          px='22px'
          mb={{ base: "0px", "2xl": "20px" }}>

          <div hidden={next > 0}>
            <Grid
              mb='10px'
              templateColumns={{
                base: "1fr",
                lg: "repeat(3, 1fr)",
              }}
              templateRows={{
                base: "1fr",
                lg: "repeat(3, 1fr)",
                "2xl": "1fr",
              }}
              px='22px'

              gap={{ base: "10px", xl: "10px" }}>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='40px'
                gridArea={{ base: "1/4/1/1" }}>
                Location Details
              </Text>
              <Flex flexDirection={"column"}>
                <Box>
                  <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                    Location Name
                  </Text>
                  <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                    <Input placeholder="eg. Zebras Field 1" value={location.name} borderRadius="16px"
                      onChange={(e) => {
                        setLocation((ps) => {
                          let update = { ...ps }
                          update.name = e.target.value
                          return { ...update }
                        })
                      }} />
                  </Text>
                </Box>
              </Flex>
              <Flex flexDirection={"column"}>
                <Box>
                  <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                    Location Address
                  </Text>
                  <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBOatRNT7-P31aBDTe1iyZKD2ulYwxzh1U"
                      minLengthAutocomplete={5}
                      apiOptions={{
                        language: "en",
                        region: "us",
                        types: ["address", "locality", "geocode", "latlng"],

                      }}
                      selectProps={{
                        onChange: async (e) => {
                          let geocode = await geocodeByPlaceId(e.value.place_id)
                          setLocation((ps) => {
                            let update = { ...ps }
                            update.zip = `${geocode[0]?.address_components[6]?.long_name}`
                            update.city = `${geocode[0].address_components[2].long_name}`
                            update.state = `${geocode[0].address_components[4].long_name}`
                            update.address1 = `${geocode[0].address_components[0].long_name} ${geocode[0].address_components[1].long_name}`
                            update.latitude = `${geocode[0].geometry.location.lat().toFixed(6)}`
                            update.longitude = `${geocode[0].geometry.location.lng().toFixed(6)}`
                            update.location = `${geocode[0].formatted_address}`
                            debugger
                            return { ...update }
                          })
                        },
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            fontSize: "16px",
                            borderRadius: "30px",
                          })
                        }
                      }}
                    />
                  </Text>
                </Box>

              </Flex>
              <Box gridArea={{ base: "2/2/4/4" }}>
                <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
                  Location Notes (Optional)
                </Text>
                <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
                  <Textarea fontWeight='500' value={location.notes} borderRadius="16px" color={textColorPrimary} placeholder="Location Details" fontSize='sm'
                    onChange={(e) => {
                      setLocation((ps) => {
                        let update = { ...ps }
                        update.notes = e.target.value
                        return { ...update }
                      })
                    }}></Textarea>
                </Text>
              </Box>
            </Grid>
          </div>


          <Flex
            flexDirection='row'
            w="100%"
            justify='flex-end'
            my="10"
          >
            {next > 0 ?
              <Button w="40" h="50" variant="active" onClick={() => {
                setNext(next - 1);
              }}>Previous</Button>
              : ""}
            {next < 0 ?
              <Button w="40" h="50" variant="brand" _hover={{ bg: "brand.600" }}
                disabled={loading} onClick={async () => {
                  setLoading(true)
                  await setTimeout(() => {
                    setNext(next + 1)
                    setLoading(false)
                  }, 1000)
                }}>{loading ?
                  <Spinner />
                  :
                  <Text color={'white'}>Next</Text>}</Button>
              : <Button w="40" h="50" variant="brand" onClick={async () => {
                setLoading(true)
                if (!location.name || !location.address1 || !location.city || !location.state || !location.zip) {
                  toast({
                    title: "Error",
                    description: "Please fill out all fields or try a different address",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  })
                  return
                }
                setLoading(true)
                try {
                  let league = await apiHelper.getLeague()
                  let resp = await apiHelper.createLocation(league.id, location)
                  if (resp) {
                    setLoading(false)
                    toast({
                      title: "Success",
                      description: "Location created",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    })
                    history.push("/league/locations")
                  } else {
                    setLoading(false)

                    toast({
                      title: "Error",
                      description: "Error creating location",
                      status: "error",
                      duration: 6000,
                      isClosable: true,
                    })
                  }
                } catch (e) {
                  setLoading(false)
                }
              }}>
                {loading ?
                  <Spinner color='white' />
                  :
                  <Text color={'white'}>Create</Text>}
              </Button>
            }
          </Flex>

        </Card>
      </Grid>
    </Box>
  );
}
