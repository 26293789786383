import React from "react";

// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";


export default function AuthStep4(props) {
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");

    const {
        auth,
        setAuth,
        error,
        ...rest } = props;


    return (
        <Flex direction={"column"}>
            <Text
                fontSize='2xl'
                fontWeight='800'
            >
                Account Created
            </Text>
            <Text
                color={textColorDetails}
                fontSize='xl'
                fontWeight='500'
                mt='24px'
            >
                Please check your email for a verification link. 
                If you do not receive an email, please check your spam folder.
            </Text>

        </Flex>
    );
}

