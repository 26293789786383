
// Chakra imports
import { Box, Flex, SimpleGrid, Skeleton, Spinner, Stack, useColorModeValue } from "@chakra-ui/react";
import GameTable from "./components/GameTable";
import {
  columnsData,
} from "./variables/columnsData";
import React, { useEffect } from "react";
import apiHelper from "helpers/apiHelper";
export default function ManageGames(props) {
  const { setToken, token, setBrand } = props
  useEffect(() => {
    setBrand("Games")
  }, [])
  

  const [res, setRes] = React.useState([
    {
      name: "",
      gameSport: "",
      startDateTime: "",
      assignedRefs: [{
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      },
      {
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      }]
    },
    {
      name: "",
      gameSport: "",
      startDateTime: "",
      assignedRefs: [{
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      },
      {
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      }]
    },
    {
      name: "",
      gameSport: "",
      startDateTime: "",
      assignedRefs: [{
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      },
      {
        "avatar": "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg",
      }]
    }
  ])
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    apiHelper.getLeagueGames().then((resp) => {
      setRes(resp)
      setLoading(false)
    }).catch((e) => {
    })
  }, [])
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {
          !loading ?
            <GameTable
              columnsData={columnsData}
              tableData={res}
              loading={loading}
            />
            : <Flex
              w={"100%"}
              h={"full"}
              position={"absolute"}
              bottom={10}
              alignItems={"center"}
              justifyContent={"center"}>
              <Flex padding={4} backgroundColor={"white"}
                alignItems={"center"}
                justifyContent={"center"}
                w={24}
                h={24}

                borderRadius={"full"}>
                <Spinner size={"xl"} color={"brand.600"} borderWidth={4} />
              </Flex>
            </Flex>
        }

      </SimpleGrid>
    </Box>
  );
}
