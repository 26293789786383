
import React from "react";
// Assets
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';


function SignIn(props) {
  const history = useHistory();
  const routeChange = (token) => {
    if (token == "")
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
    let path = `/`;
    history.push(path);
  }
  return (
    <div>
      {routeChange("")}
    </div>
  );
}
SignIn.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func,
};

export default SignIn;
