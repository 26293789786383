export const columnsData = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "SPORT",
    accessor: "game_type",
  },
  {
    Header: "GAME DATE",
    accessor: "game_date",
  },
  {
    Header: "MANAGE",
    accessor: "id",
  },
];
