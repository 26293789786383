export const columnsData = [
  {
    Header: "NAME",
    accessor: "name",
  },

  {
    Header: "CITY",
    accessor: "city",
  },
  {
    Header: "MANAGE",
    accessor: "id",
  },
];
