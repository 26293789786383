
import React, { useEffect } from "react";
import apiHelper from "helpers/apiHelper";

function ManageBilling() {

  useEffect(() => {
    const load = async () => {
      const url = await apiHelper.getStripeCheckout()
      window.location.href = url;
    }
    load();
  }, [])
  

  return (
    <div></div>
  )
}

export default ManageBilling;