import React, { useState } from 'react';
import { StarIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';

const StarRating: React.FC<{ rating: number; error?: string, onChange?: (rating: number) => void }> = ({
  rating,
  onChange,
  error
}) => {
  const [hoverRating, setHoverRating] = useState(0);
  const isEnabled = !!onChange;

  const handleMouseEnter = (index: number) => {
    isEnabled && setHoverRating(index);
  };

  const handleMouseLeave = () => {
    isEnabled && setHoverRating(0);
  };

  const handleClick = (index: number) => {
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <Box border={!!error ? "2px solid red" : "none"} borderRadius="md" p="2">
    {[...Array(5)].map((_, i) => (
      <StarIcon
        key={i}
        color={(hoverRating || rating) >= i + 1 ? 'yellow.500' : 'gray.300'}
        onMouseEnter={() => handleMouseEnter(i + 1)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(i + 1)}
        style={{ cursor: 'pointer' }}
      />
    ))}
    {!!error && <Text color="red.500">{error}</Text>}
  </Box>
  );
};

export default StarRating;
